<template>
  <div class="creator" ref="sellerRef">
    <h3>Publisher/Creator Application</h3>
    <a-form style="border-bottom: 1px solid #F3F2F7;">
      <a-form-item v-bind="validateInfos.publicImageSource">
        <div class="input">
          <span>Image</span>
          <UploadImage 
            :configs="{
              maxCount:1,
              maxSize:5,
              // minWidth:600,
              // minHeight:600,
              spin:true
            }" 
            @upload-file="uploadImg" 
            #content
          >
          <div class="avatar-uploader">
            <div v-if="modelRef.publicImageSource" class="upload-img">
              <img :src="modelRef.publicImage || modelRef.publicImageSource" alt="">
            </div>
            <div v-if="!modelRef.publicImageSource" class="upload-icon"> 
              <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan-xi"></use>
              </svg>
            </div>
          </div>
          <!-- <div class="tips" style="margin-top: 5px">Suggested image size 600px * 600px</div> -->
          <div style="text-align: center;width: 120px;margin-top: 10px;" v-if="modelRef.publicImageSource">
                <artmisads-select-button size="small" style="width: 100%;" @click="onEditImg">Edit Image</artmisads-select-button>
                <p class="subtitle-btn">Replace Image</p>
          </div>
        </UploadImage>
        <image-cropper 
          @cropped-file="cropperImg"
          @onCancel="openCropper = false"
          :open="openCropper" 
          :coverFile = "modelRef.publicImageSource"
          />
        </div>
      </a-form-item>
      <a-form-item
        v-bind="validateInfos.publicBio"
      >
        <div class="input" style="margin-top: 32px;">
          <span>Bio</span>
          <artemisads-textarea @blur="() => validate('publicBio')" :rows="4" v-model:value="modelRef.publicBio"/>
          <p class="tips" style="margin-top: 8px;">You can edit your bio and other proﬁle details directly on the platform.</p>
        </div>
      </a-form-item>
      <a-space direction="vertical" style="width: 100%;">
          <a-alert message="" description="Approval may take up to 2 business days. We prioritize publishers/
          creators with relevant audiences. If you’re not approved within this
          time, it may mean you didn’t meet the current criteria. Feel free to
          check back later, as requirements may change." type="warning" show-icon>
          <template #icon>
            <SvgIcon name="info" style="font-size: 16px;"></SvgIcon>
          </template>
          </a-alert>
      </a-space>
      <a-form-item style="margin-top: 28px;">
          <artemisads-divid/>
        </a-form-item>
    </a-form> 
    <div style="display: flex; justify-content: space-between;margin-top: 28px;">
          <artmisads-button style="border-radius: 20px;" @click="onJump(2)">
              <svg-icon name="jiantou-zuo-cu" style="vertical-align: 0.01em;"/>Back
          </artmisads-button>
          <artmisads-button type="primary" style="border-radius: 20px;" @click="onSubmit()">Submit Application
          </artmisads-button>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted, reactive, toRaw } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { Form, message } from 'ant-design-vue';
  import { useUserInfoStore } from '../../storeData/index';
  import jwt from 'jsonwebtoken';

  import { profileApi } from '~/server/index'

  import SvgIcon from '~/components/SvgIcon.vue';
  import ImageCropper from '~/components/ImageCropper.vue';
  import UploadImage from '~/components/uploadImage.vue';
  
  const store = useUserInfoStore();

  const useForm = Form.useForm;
  const props = defineProps(['goTo']);
  // const route = useRoute();
  const router = useRouter()

  const openCropper = ref(false);

  const modelRef = reactive({
    publicImage: '',
    publicImageSource: '',
    publicBio: '',
  });

  const rulesRef = reactive({
    publicImage: [{
      required: true,
      message: 'Public Image cannot be empty'
    }],
    publicBio: [{
      required: true,
      message: 'A proﬁle bio is required.',
      trigger: ['change']
    }, {
      min: 10,
      message: 'It should have a minimum of 10 characters.',
      trigger: ['blur']
    }, {
      max: 10000,
      message: 'It must not exceed 10,000 characters',
      trigger: ['blur']
    }],
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  const onJump = (step) => {
        console.log(toRaw(modelRef));
        localStorage.setItem('profile-step-4', JSON.stringify(toRaw(modelRef)));
        props.goTo(step)
  }

  const onEditImg = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openCropper.value = true
  }

  const onSubmit = () => {
    validate().then(res => {
        if (!res.errorFields) {
          const localStoragePrefix = location.hostname;
          const userInfo = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);
          const info = userInfo ? JSON.parse(userInfo) : {};
          const step2 = JSON.parse(localStorage.getItem('profile-step-2'));
          const step3 = JSON.parse(localStorage.getItem('profile-step-3'));
          let formState = {
            creatorEmail: step2.creatorEmail,
            creatorName: step2.creatorName,
            links: step2.links,
            promotionCountryCodes: step3.promotionCountryCodes,
            promotionMethodIds: step3.promotionMethodIds,
            promotionProductCategoryIds: step3.promotionProductCategoryIds,
            publicBio: modelRef.publicBio,
            publicImage: modelRef.publicImage,
            publicImageSource: modelRef.publicImageSource 
          }
          if(info.userType === "publisher" && info.reviewStatus === 'Rejected') {
              profileApi.updateProfile(formState).then(res => {
                  if(res.success) {
                      message.success('Update Success!')
                      setTimeout(() => {
                        localStorage.clear();
                        router.replace(`/sign-in`);  
                      }, 300);
                  }
              });
          } else {
              profileApi.completeProfile({publisherProfile: formState, userType: 'publisher'}).then(res => {
                if(res.success) {
                  localStorage.removeItem('profile-step-2');
                  localStorage.removeItem('profile-step-3');
                  localStorage.removeItem('profile-step-4');
                  const userToken = res.data.token;
                  const artmisAdsUserData = jwt.decode(userToken);
                  store.setUserInfo(artmisAdsUserData, userToken);
                  router.replace(`/publisher/profile`);
                }
              });
          }
        }
    })
  }

  const uploadImg = (res) => {
      try {
        modelRef.publicImageSource = res.data.url;
        console.log(modelRef.publicImageSource);
        openCropper.value = true;
      } catch (error) {
        console.log('upload-false');
        
      }
  }

  const cropperImg = (res =>{
    try {
        modelRef.publicImage = res.data.url;
        console.log(modelRef.publicImage);
        openCropper.value = false;
      } catch (error) {
        console.log('upload-false');
      }
  })

  onMounted(() => {
      const step4 = localStorage.getItem('profile-step-4') && JSON.parse(localStorage.getItem('profile-step-4'));
      Object.assign(modelRef, step4);
  })


</script>
<style lang="less" scoped>
  .creator{
    height: 600px;
    padding-bottom: 50px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari 和 Edge */
    }
    .tips {
      opacity: 0.5;
      font-family: Sora;
      font-weight: 400;
      font-size: 13px;
      color: #0F0629;
      letter-spacing: 0.3px;
      line-height: 18px;
    }
    :deep(.ant-alert) {
      margin-top: 40px;
      background: #EAE8F0;
      border: 1px solid #E8E5ED;
      border-radius: 8px;
      box-shadow: none;
      width: 100%;
      padding: 8px;
      line-height: 1.35;
      // color: var(--dark-3);
      .anticon {
        color: #7F7A90;
        margin-right: 8px;
        margin-top: 2px;
      }
      .ant-alert-description {
        color: var(--dark-3);
      }
    }
    > h3 {
      height: 36px;
      font-family: Sora-Medium;
      font-weight: 500;
      font-size: 28px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 36px;
    }
    .avatar-uploader > .ant-upload {
    width: 200px;
    height: 200px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  :deep(.ant-upload) {
    .ant-spin .ant-spin-dot {
            // margin: 0;
            top: 40%;
      }
  }


  .avatar-uploader {
    width: 120px;
    height: 120px;
    background: var(--bg-gray-6);
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: var(--bg-gray-4);
      .upload-icon {
        border-color: var(--bg-gray-7);
      }
    }
    .upload-icon{
      border: 1px dashed var(--border-1);
      border-radius: 8px;
      font-size: 14px;
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      color: var(--dark-3);
    }
    .upload-img{
      width: 100%;
      height: 100%;
      border-radius: 8px;
      img{
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
    .input {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      // width: 480px;
      padding: 1px;
      > span {
        height: 16px;
        opacity: 0.7;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      > input {
        height: 40px;
      }
    }
    .link-card {
      margin-top: 32px;
      border: 1px solid #5436E9;
      border-radius: 10px;
      padding: 20px;
      .input {
          width: 440px;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    :deep(.ant-select-item-option-content) {
      display: flex;
      > img {
        width: 22px;
        height: 16px;
        align-self: center;
        margin-right: 12px;
      }
    }
  }

  .subtitle-btn {
    margin-top: 6px;
    color: var(--dark-4);
    cursor: pointer;
    &:hover {
      color: var(--dark-3);
    }
  }
  
</style>