<template>
  <div class="connecting">
    <h3>{{ title ? title :'Connecting your Amazon account' }}</h3>
    <div class="content">
      <a-spin size="large"/>
      <p>Please be patient, this will take a second...</p>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>
  import jwt from 'jsonwebtoken';
  import { watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { notification } from 'ant-design-vue';
  
  import { profileApi } from '../../server/index'
  import { useUserInfoStore } from '../../storeData/index';

  const store = useUserInfoStore();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };

  const props = defineProps(['goTo', 'title']);
  const route = useRoute();

  async function completeProfile (amazonCode) {
    const params = JSON.parse(localStorage.getItem('userProfile'));
    if (!params) {
      props.goTo(1);
    }
    params.sellerProfile.amazonAuthorizationCode = amazonCode;
    const res = await profileApi.completeProfile(params);
    const { success, data, error } = res;
    if (success) {
      const userToken = data.token;
      const artmisAdsUserData = jwt.decode(userToken);
      store.setUserInfo(artmisAdsUserData, userToken);
      localStorage.setItem('userFirstComeIn', '1');
      const timer = setTimeout(() => {
        location.href = '/#/admin/welcome';
        clearTimeout(timer);
      }, 300)
    } else {
      openNotification(error);
      const timer = setTimeout(() => {
        props.goTo(1);
        clearTimeout(timer);
      }, 500)
    }
    localStorage.removeItem('userProfile');
  }

  watch(route, () => {
    const amazonCode = route.query.code;
    if (amazonCode) {
      completeProfile(amazonCode);
    }
  })

</script>
<style lang="less" scoped>
  .connecting {
    display: flex;
    flex-direction: column;
    .content {
      margin-top: 24px;
      width: 448px;
      height: 240px;
      padding: 77px 104px;
      background: var(--color-white);
      box-shadow: var(--section-box-shadow-2);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      > div {
        align-self: center;
      }
      > p {
        margin-top: 16px;
        width: 239px;
        height: 40px;
        font-family: Sora-Regular;
        text-align: center;
        line-height: 20px;
      }
    }
  }
</style>