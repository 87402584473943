<template>
  <div class="select-user-type">
    <h3>Welcome to ArtemisAds</h3>
    <p>Are you a Seller or a Creator?</p>
    <div class="seller" @click="props.goTo(1, 1)">
      <div class="icon">
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-shangjia"></use>
        </svg>
      </div>
      <div class="text">
        <span>Seller</span>
        <span>I have an Amazon Seller or Vendor account</span>
      </div>
    </div>
    <div class="creator">
      <div class="icon">
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-a-wode4"></use>
        </svg>
      </div>
      <div class="text">
        <span>Publisher/Creator</span>
        <span>I want to promote Amazon products</span>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted } from "vue";

  const props = defineProps(['goTo']);

</script>

<style lang="less" scoped>
  .select-user-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > p {
      height: 20px;
      font-family: Sora-Regular;
      color: var(--dark-3);
      line-height: 20px;
      margin-top: 8px;
    }
    .seller, .creator {
      width: 424px;
      height: 72px;
      padding-top: 15px;
      padding-left: 16px;
      background: var(--color-white);
      border: var(--border);
      box-shadow: var(--section-box-shadow-2);
      border-radius: 8px;
      display: flex;
      cursor: pointer;
      &:hover {
        box-shadow: var(--section-box-shadow);
      }
      .icon {
        width: 40px;
        height: 40px;
        background: var(--yellow-2);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        svg {
          width: 24px;
          height: 24px;
          align-self: center;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 16px;
        > span {
          &:first-child {
            height: 26px;
            font-weight: 500;
            font-size: 18px;
            letter-spacing: 0.5px;
            line-height: 26px;
          }
          &:last-child {
            height: 20px;
            color: var(--dark-2);
            font-family: Sora-Regular;
            line-height: 20px;
          }
        }
      }
    }
    .seller {
      margin-top: 32px;
      .icon {
        background: var(--primary-icon);
      }
    }
    .creator {
      margin-top: 12px;
      .icon {
        background: var(--yellow-2);
      }
    }
  }
</style>